import React from 'react';
import './App.css';
import Section from './section'

import { MuiThemeProvider, createMuiTheme, CssBaseline, List, createStyles, makeStyles, Theme } from '@material-ui/core';
//import orange from '@material-ui/core/colors/orange';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import WorkIcon from '@material-ui/icons/Work';

//const appOrange = orange[500]

//https://material-ui.com/customization/palette/
// https://material-ui.com/customization/default-theme/
const theme = createMuiTheme({
  palette: {
    background: {
        default: '#F9F9FA'
    },
    text: {
    }
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    dividerInset: {
      margin: `5px 0 0 ${theme.spacing(9)}px`,
    },
  }),
);

export default function App() {
  const classes = useStyles();
  
  var data = [
      {
        title : "Home",
        body : "New York City",
        icon: <HomeOutlinedIcon style={{ color: 'black' }} />
      },
      {
        title : "Personal Projects",
        body : <a href="https://www.lenyceats.com" rel="noreferrer noopener" target="_blank">Le NYC Eats</a>,
        icon: <SentimentVerySatisfiedIcon style={{ color: 'green' }} />
      },
      {
        title : "Employment & Education",
        body : <a href="https://www.linkedin.com/in/ngattuso/" target="_blank" rel="noreferrer noopener">View on LinkedIn</a>,
        icon: <WorkIcon style={{ color: 'brown' }} />
      },
    ]

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <List className={classes.root}>
        {data.map(nextSection => (
            <Section title={nextSection.title} body={nextSection.body} icon={nextSection.icon} />
        ))}
      </List>
    </MuiThemeProvider>
  );
}
