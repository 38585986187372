import React from 'react';

import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';

export interface SectionProps {
  title:string
  icon:React.ReactElement
  body:string|React.ReactElement
}

/*
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    dividerInset: {
      margin: `5px 0 0 ${theme.spacing(9)}px`,
    },
  }),
);
*/

export default function Section(props:SectionProps) {
  //const classes = useStyles();
  
  return (
      <ListItem>
        <ListItemIcon>
        {props.icon}
        </ListItemIcon>
        <ListItemText primary={props.title} secondary={props.body} primaryTypographyProps={
          { style: 
            {
              fontSize:14, 
              fontWeight:100, 
              color:"gray"
            }
          }} secondaryTypographyProps={
          { 
            style: 
             {
               fontWeight:900, 
               color:'black'
              }}}  />
    </ListItem>
  );
}
//secondaryTypographyProps={{ style: {color: 'black', fontSize:20} }}
